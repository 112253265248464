// Constant imports
import constants from '../../App.constants';

// Material imports
import Link, { LinkProps } from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// Router imports
import { Link as RouterLink, useLocation } from 'react-router-dom';

export const breadcrumbNameMap: { [key: string]: string } = {
  '/': constants.labels.dashboard,
  '/customer': constants.labels.customers,
  '/customer/add': constants.labels.addCustomer,
  '/work-order': constants.labels.workOrder,
};

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

export const NavigationBreadCrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      separator={<NavigateNextIcon fontSize='medium' />}>
      <LinkRouter underline='none' color='text.white' to='/'>
        {constants.labels.dashboard}
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        return last ? (
          <Typography color='text.primary' key={to}>
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <LinkRouter underline='none' color='inherit' to={to} key={to}>
            {breadcrumbNameMap[to]}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};
