// React import
import React, { useEffect, useState } from 'react';

// Constants import
import constants from '../../App.constants';

// Modal import
import { LoadingModal } from '../../components/Modals';

// Component import
import RecordCard from '../../components/RecordCard';

// Service import
import { getDashboardStats } from '../../services/miscellaneous.service';

// Util import
import { formateDate } from '../../Utils/date.util';

// Dashboard Container
const Dashboard = () => {
  // Manages total customers state
  const [dashboardStats, setDashboardStats] = useState<any>({});

  // Manages Loader state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDashboardStats()
      .then((response: any) => {
        if (response.data.data) {
          setIsLoading(false);
          setDashboardStats(response.data.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setDashboardStats({});
        console.log(error);
      });
  }, []);

  return (
    <div>
      <LoadingModal isLoading={isLoading} />
        <div className='flex flex-col lg:flex-row gap-5'>
          <RecordCard
            recordType={constants.recordTypes.vendors}
            count={dashboardStats?.vendors?.count || 0}
            lastUpdated={formateDate(dashboardStats?.vendors?.lastAdded) || '-/-/-'}
          />
          <RecordCard
            recordType={constants.recordTypes.customers}
            count={dashboardStats?.customers?.count || 0}
            lastUpdated={formateDate(dashboardStats?.customers?.lastAdded) || '-/-/-'}
          />
          <RecordCard
            recordType={constants.recordTypes.orders}
            count={dashboardStats?.workOrders?.count || 0}
            lastUpdated={formateDate(dashboardStats?.workOrders?.lastAdded) || '-/-/-'}
          />
        </div>
    </div>
  );
};

export default Dashboard;
