// React imports
import React from 'react';

// Material imports
import { Modal } from '@mui/material';
import { Cancel } from '@mui/icons-material';

// ActionResultModal props interface
export interface ActionResultModalProps {
  open: boolean;
  onClose: () => void;
  message: string;
  type: string;
}

export const ActionResultModal = (props: ActionResultModalProps) => {
  const { onClose, open, message, type } = props;

  const handleClose = () => onClose();

  const pickColor = () => {
    switch (type) {
      case 'success': {
        return 'text-[#363B64]';
      }
      case 'error': {
        return 'text-[#FF655C]';
      }
    }
  };
  return (
    <Modal
      onClose={handleClose}
      open={open}
      className='flex flex-row justify-center items-center'>
      <div className='flex flex-col h-[14rem] max-w-[90%] md:h-[16.25rem] md:w-[33rem] lg:w-[36rem] bg-white rounded-3xl shadow-3xl focus-visible:outline-none'>
        <div className='flex flex-row justify-end pr-3 pt-2'>
          <button
            className='text-[#A098AE]'
            onClick={handleClose}>
            <Cancel />
          </button>
        </div>
        <div className='flex flex-row justify-center py-10 h-full w-full'>
          <p
            className={`text-[2.2rem] max-w-[80%] text-center ${pickColor()}`}>
            <b>{message}</b>
          </p>
        </div>
      </div>
    </Modal>
  );
};
