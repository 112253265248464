// React import

// Material import
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LogoutIcon from '@mui/icons-material/Logout';

// Component import
import Navigation from '../../components/Navigation';
import {
  breadcrumbNameMap,
  NavigationBreadCrumbs,
} from '../../components/NavigationBreadCrumbs';

// Routes import
import ProtectedRoutes from '../../routes/ProtectedRoutes';

// Router import
import { useLocation, useNavigate } from 'react-router-dom';

// Service import
import { logout } from '../../services/auth.service';
import constants from '../../App.constants';

// Home Container
const Home = () => {
  let navigate = useNavigate();
  let location = useLocation();
  return (
    <Box className='flex flex-col w-full min-h-screen font-inter bg-[#ecebeb]'>
      <div className='hidden md:flex flex-row bg-primary w-full h-[4rem] items-center px-6 z-50 shadow-md'>
        <img
            className='w-[3.075rem] h-[2.3125rem]'
            src={constants.svgs.logoTurn}
            alt='Turn Tech Logo'
          />
      </div>
      <div className='flex-row w-full hidden md:flex'>
        <Navigation />
        <Box component='main' className='w-full p-7 z-10'>
          <div className='bg-white flex flex-row w-full justify-between px-4 py-2 rounded-lg'>
            <div>
              <p className='font-bold text-2xl mb-2'>{'Dashboard'}</p>
              <NavigationBreadCrumbs />
            </div>
          </div>
          <Box className='h-[83%] w-full mt-8'>
            <Box className='w-full h-full'>
              <ProtectedRoutes />
            </Box>
          </Box>
        </Box>
      </div>
      <div className='flex flex-col md:hidden'>
        <div className='flex flex-row justify-between items-center px-5 bg-primary text-[#FAFAFC] h-[5rem] fixed w-full z-50 shadow-md'>
          <button onClick={() => navigate(-1)}>
            <ArrowBackIosIcon sx={{fontSize: '1.6rem'}} />
          </button>
          <p className='font-bold text-xl'>
            {breadcrumbNameMap[location.pathname]}
          </p>
          <button onClick={() => logout()}>
            <LogoutIcon sx={{fontSize: '1.6rem'}}/>
          </button>
        </div>
        <div className='mx-4 my-[6rem]'>
            <ProtectedRoutes />
        </div>
        <Navigation />
      </div>
    </Box>
  );
};

export default Home;
