import React from 'react';
import Home from './containers/Home';
import PublicRoutes from './routes/PublicRoutes';

function App() {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  return (
    <div className='min-h-screen min-w-full overflow-hidden'>
      {isAuthenticated ? <Home /> : <PublicRoutes />}
    </div>
  );
}

export default App;
