// React imports
import React, { useState } from 'react';

// Material imports
import { KeyboardBackspace } from '@mui/icons-material';

// Constants imports
import constants from '../../App.constants';

// Component imports
import { ActionResultModal } from '../../components/Modals';

// Service imports
import { addCustomerWithXlsxFile } from '../../services/customer.service';

// Router import
import { useNavigate } from 'react-router-dom';

// Material imports
import LinearProgress from '@mui/material/LinearProgress';

// Customer Container
export const AddCustomer = () => {
  let navigate = useNavigate();
  // Manages xlsx file validity state
  const [xlsxValid, setXlsxValidity] = useState(false);

  // Manages xlsx file upload status state
  const [xlsxUploaded, setXlsxUploaded] = useState(false);

  // Manages xlsx file state
  const [uploadedFile, setUploadedFile] = useState(null);

  // Manages name of the Xlsx file state
  const [uploadedFileName, setUploadedFileName] = useState('');

  // Manages ActionResultDailog open/close state
  const [openDialogSwitch, setOpenDialogSwitch] = useState(false);

  // Manages ActionResultDialog type state
  const [actionDialogDetails, setActionDialogDetails] = useState({
    message: '',
    type: '',
  });

  // Manages Loader state
  const [isLoading, setIsLoading] = useState(false);

  // Manages Error message state
  const [uploadFailedMessage, setUploadFailedMessage] = useState('');

  /**
   * Funtion to handle dialog
   */
  const handleDialog = (message: string, type: string) => {
    setOpenDialogSwitch(!openDialogSwitch);
    setActionDialogDetails({ message, type });
  };

  /**
   * Funtion to handle file
   * @param event
   */
  const handleFile = (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (constants.validXlsxFileTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => {
        setXlsxValidity(true);
        setXlsxUploaded(true);
        setUploadedFile(file);
        setUploadedFileName(file.name);
      };

      reader.onerror = (error: any) => {
        setXlsxValidity(false);
        setXlsxUploaded(false);
        console.log(error);
      };

      reader.readAsText(file);
    } else {
      setXlsxValidity(false);
      setUploadFailedMessage(constants.messages.uploadValidXLSX);
      return;
    }
  };

  /**
   * Funtion to upload the xlsx file
   */
  const uploadXlsxFile = () => {
    setIsLoading(true);
    if (uploadedFile) {
      addCustomerWithXlsxFile(uploadedFile)
        .then((response) => {
          if (response.data.data.message) {
            setIsLoading(false);
            handleDialog(response.data.data.message, 'success');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if(error.response.data.errors) {
            setUploadFailedMessage(error.response.data.errors[0].message);
          } else {
            setUploadFailedMessage('Something went wrong!');
          }
        });
    }
    setXlsxValidity(false);
    setXlsxUploaded(false);
    setUploadedFile(null);
    setUploadedFileName('');
  };

  return (
    <main className='h-full w-full bg-white rounded-lg p-4'>
      <ActionResultModal
        onClose={() => setOpenDialogSwitch(!openDialogSwitch)}
        open={openDialogSwitch}
        type={actionDialogDetails.type}
        message={actionDialogDetails.message}
      />
      <div className=''>
        <div className='flex flex-row gap-3 items-center'>
          <button
            className='rounded-full bg-[#EBEBEB] text-[#2E3192] p-1'
            onClick={() => navigate(-1)}>
            <KeyboardBackspace />
          </button>
          <p className='font-bold text-xl'>{constants.labels.uploadXlsx}</p>
        </div>
        <div className='flex flex-row justify-center w-full mt-10'>
          <div className='flex flex-row justify-center items-center bg-[#f1f4fc78] w-4/5 md:w-2/4 h-[15rem] rounded-[2rem] shadow-light'>
            {isLoading ? (
              <div className='flex flex-col justify-center items-center w-4/5 gap-6'>
                <LinearProgress
                  color='primary'
                  sx={{ height: '1.5rem', width: '100%', borderRadius: '1rem' }}
                />
                <p className='text-[#A098AE] text-xl'>
                  {constants.labels.uploading}...
                </p>
              </div>
            ) : (
              <div className='flex flex-col gap-4 justify-center items-center w-full'>
                <div
                  className='flex flex-row justify-center items-center bg-white h-[3.5rem] w-[10rem] rounded-xl shadow-light'
                  onClick={() => {
                    setUploadFailedMessage('');
                  }}>
                  <p className='text-lg text-primary px-2'>
                    <b>+</b> {constants.labels.uploadXlsx}
                  </p>
                  <input
                    type='file'
                    onChange={handleFile}
                    className='opacity-0 absolute cursor-pointer'
                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/xlsx'
                  />
                </div>
                {uploadFailedMessage && (
                  <p className='text-red-600 text-center px-2'>
                    {uploadFailedMessage}
                  </p>
                )}
                {xlsxValid && xlsxUploaded && (
                  <div className='flex flex-col lg:flex-row text-md text-[#363B64] max-w-[99%] text-center px-2 lg:gap-1'>
                    <b>{constants.labels.selectedFile}:</b>
                    <p className='truncate'>{uploadedFileName}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='flex flex-row justify-center mt-7'>
          <button
            className={
              xlsxValid && xlsxUploaded
                ? 'bg-primary w-[8.0625rem] h-[3rem] text-white rounded-xl font-semibold text-xl cursor-pointer'
                : 'bg-[#80839d] w-[8.0625rem] h-[3rem] text-white rounded-xl font-semibold text-xl cursor-pointer'
            }
            onClick={uploadXlsxFile}
            disabled={!xlsxValid || !xlsxUploaded}>
            {constants.labels.upload}
          </button>
        </div>
      </div>
    </main>
  );
};
