const constants = {
  apiBaseUrl: '/turn/api/v1/', // for AWS

  apis: {
    dashboardStats: 'admins/dashboard/stats',
    adminLogin: 'auth/admins/login',
    customerDetails: 'admins/customer-details',
    workOrders: 'admins/work-orders',
  },

  recordTypes: {
    vendors: 'vendors',
    customers: 'customers',
    orders: 'orders',
  },

  svgs: {
    logoTurn: require('./assets/images/logo-turn.svg').default,
    iconDashBoard: require('./assets/images/icon-dashboard.svg').default,
    iconDashBoardActive: require('./assets/images/icon-dashboard-active.svg').default,
    iconDashBoardMobile: require('./assets/images/icon-dashboard-mobile.svg').default,
    iconDashBoardMobileActive: require('./assets/images/icon-dashboard-mobile-active.svg').default,
    iconCustomer: require('./assets/images/icon-customer.svg').default,
    iconCustomerActive: require('./assets/images/icon-customer-active.svg').default,
    iconCustomerMobile: require('./assets/images/icon-customer-mobile.svg').default,
    iconCustomerMobileActive: require('./assets/images/icon-customer-mobile-active.svg').default,
    iconWorkOrder: require('./assets/images/icon-work-order.svg').default,
    iconWorkOrderActive: require('./assets/images/icon-work-order-active.svg').default,
    iconWorkOrderMobile: require('./assets/images/icon-work-order-mobile.svg').default,
    iconWorkOrderMobileActive: require('./assets/images/icon-work-order-mobile-active.svg').default,
    iconSearch: require('./assets/images/icon-search.svg').default,
    iconLogout: require('./assets/images/icon-logout.svg').default,
    iconTurnMobile: require('./assets/images/icon-turn-mobile.svg').default,
  },

  gifs: {
    loaderTurn: require('./assets/gifs/turn-tech-loader.gif'),
    loaderTurnWhite: require('./assets/gifs/turn-tech-loader-white.gif'),
  },

  validXlsxFileTypes: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/xlsx',
  ],

  labels: {
    customer: 'Customer',
    customers: 'Customers',
    dashboard: 'Dashboard',
    signIn: 'Sign in',
    addCustomer: 'Add Customer',
    totalOrders: 'Total Orders',
    lastOrderDate: 'Last Order Date',
    uploadXlsx: 'Upload XLSX',
    uploading: 'Uploading',
    selectedFile: 'Selected file',
    total: 'Total',
    allCustomers: 'All customers',
    submit: 'Submit',
    totalNoOf: 'Total no. of {record}',
    lastAdded: 'Last {record} added',
    upload: 'Upload',
    workOrder: 'Work Order',
    workOrders: 'Work Orders',
    completedWorkOrders: 'Completed Work Orders'
  },

  messages: {
    welcomeLoginForm: 'Welcome to Turntech',
    loginForm: 'Enter your registered email address and password to login',
    noCustomersFound: 'Please click on the following button to add Customers.',
    noWorkOrdersFound: 'No Work Orders found for the specified Date Range.',
    uploadValidXLSX: 'Please upload a valid .xlsx file!',
  },

  workOrderColumns: ['Date', 'Order Number', 'Customer', 'MT', 'SP', 'Apartment', 'Service' ,'Service Type', 'Status'],
  customersListColumns: ['Customers', 'Total Orders', 'Last Order Date'],

  recordsPerPage: 9,
};

export default constants;
