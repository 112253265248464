// React Imports
import * as React from "react";
import { useState } from "react";

// Constant Imports
import constants from "../../App.constants";

// Material UI imports
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

// Component imports
import { LoadingModal } from "../../components/Modals";

// Service import
import {
  downloadWorkOrders,
  getWorkOrders,
} from "../../services/workOrder.service";

// Third party imports
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ExpandMore } from "@mui/icons-material";
import { formateDate, getDateTime } from "../../Utils/date.util";

const WorkOrders = () => {
  // Manages page state
  const [page, setPage] = useState(1);

  // Manages totalPages state
  const [totalPages, setTotalPages] = useState(1);

  // Manages totalRecords state
  const [totalRecords, setTotalRecords] = useState(0);

  // Manages recordsOnCurrentPage state
  const [recordsOnCurrentPage, setRecordsOnCurrentPage] = useState(0);

  // Manages workOrders state
  const [workOrders, setWorkOrders] = useState([]);

  // Manages Loader state
  const [isLoading, setIsLoading] = useState(true);

  const [selectedDateRange, setSelectedDateRange]: any = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [orderStatus, setOrderStatus] = React.useState("All");

  const [selectedDateRangeFormated, setSelectedDateRangeFormated] =
    React.useState<any>({
      from: new Date().toISOString().split("T")[0],
      till: new Date().toISOString().split("T")[0],
    });

  /**
   * Function to handle page change
   * @param {Event} event
   * @param {number} value
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Function to handle onClose of date range picker
   */
  const handleClose = () => {
    setPage(1);
    setTotalPages(1);
    let startDate = new Date(selectedDateRange[0].startDate);
    let endDate = new Date(selectedDateRange[0].endDate);
    if (startDate != endDate) {
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);
    }
    let formatedDateRange = {
      from: startDate.toISOString().split("T")[0],
      till: endDate.toISOString().split("T")[0],
    };
    setSelectedDateRangeFormated(formatedDateRange);
    setAnchorEl(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setPage(1);
    setTotalPages(1);
    setOrderStatus(event.target.value as string);
  };

  const handleDownloadClick = async () => {
    let statusToSend;
    if (orderStatus !== "All") {
      statusToSend = orderStatus;
    }
    await downloadWorkOrders(selectedDateRangeFormated, statusToSend)
      .then((response: any) => {
        console.log(response);
        setIsLoading(false);
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          "WorkOrders-" + new Date().getTime() + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  /**
   * Function to fetch work orders
   */
  const fetchWorkOrders = async () => {
    setIsLoading(true);
    let statusToSend;
    if (orderStatus !== "All") {
      statusToSend = orderStatus;
    }
    await getWorkOrders(selectedDateRangeFormated, page, statusToSend)
      .then((response: any) => {
        if (response.data.data.items) {
          setWorkOrders(response.data.data.items);
        }
        if (response.data.data.meta) {
          setRecordsOnCurrentPage(response.data.data.meta.itemCount);
          setTotalPages(response.data.data.meta.totalPages);
          setTotalRecords(response.data.data.meta.totalItems);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const StatusBadge = ({ status }: any) => {
    let statusBgClass;
    switch (status) {
      case "COMPLETED":
        statusBgClass = "bg-emerald-500";
        break;
      case "REQUESTED":
        statusBgClass = "bg-cyan-500";
        break;
      case "SCHEDULED":
        statusBgClass = "bg-purple-400";
        break;
      case "CANCELLED":
        statusBgClass = "bg-rose-500";
        break;
      case "DRAFT":
        statusBgClass = "bg-amber-500";
        break;
      // case "IN-PROGRESS":
      //   statusBgClass = "bg-red-500";
      //   break;
      // case "DECLINED":
      //   statusBgClass = "bg-violet-600";
      //   break;

      default:
        statusBgClass = "";
    }
    return (
      <p
        className={`text-white font-bold text-[0.65rem] ${statusBgClass} py-1 shadow-md px-2 rounded-lg max-w-max`}
      >
        {status}
      </p>
    );
  };

  React.useEffect(() => {
    if (selectedDateRangeFormated) {
      fetchWorkOrders();
    }
  }, [page, orderStatus, selectedDateRangeFormated]);

  return (
    <div className="h-full bg-white rounded-lg p-6">
      <LoadingModal isLoading={isLoading} />
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-6 h-[33rem] md:h-[38rem]">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-2">
            <div className="flex flex-row gap-2 items-center">
              <p className="font-bold text-xl min-w-[8rem]">
                {constants.labels.workOrders}
              </p>
              <FormControl size="small" variant="outlined">
                <InputLabel id="simple-select-label">Order Status</InputLabel>
                <Select
                  className="w-[10rem]"
                  labelId="simple-select-label"
                  value={orderStatus || "All"}
                  label="Order Status"
                  onChange={handleChange}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"REQUESTED"}>Requested</MenuItem>
                  <MenuItem value={"SCHEDULED"}>Scheduled</MenuItem>
                  <MenuItem value={"DRAFT"}>Draft</MenuItem>
                  <MenuItem value={"COMPLETED"}>Completed</MenuItem>
                  <MenuItem value={"CANCELLED"}>Cancelled</MenuItem>
                  {/* <MenuItem value={"IN-PROGRESS"}>In Progress</MenuItem> */}
                  {/* <MenuItem value={"DECLINED"}>Declined</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-col md:flex-row gap-2 items-start">
              <button
                className="flex flex-row rounded-md bg-transparent border-2 p-1 gap-1 justify-between w-full md:max-w-max"
                onClick={handleClick}
              >
                <CalendarMonthIcon />
                <span className="text-md">
                  {selectedDateRange[0]?.startDate &&
                  selectedDateRange[0]?.endDate
                    ? formateDate(selectedDateRange[0]?.startDate) +
                      " - " +
                      formateDate(selectedDateRange[0]?.endDate)
                    : "Date range"}
                </span>
                <ExpandMore />
              </button>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <div className="flex flex-col pb-2">
                  <DateRange
                    editableDateInputs={true}
                    dateDisplayFormat="M/dd/yyyy"
                    onChange={(item: any) =>
                      setSelectedDateRange([item.selection])
                    }
                    moveRangeOnFirstSelection={false}
                    ranges={selectedDateRange}
                    maxDate={new Date()}
                    showMonthAndYearPickers={false}
                    showPreview={true}
                    rangeColors={["#00AEEF"]}
                    className="shadow-sm rounded"
                  />
                  <div className="flex flex-row justify-end">
                    <button
                      className="bg-primary px-4 py-1 text-white rounded-lg font-semibold text-md cursor-pointer max-w-min mr-2 mt-1"
                      onClick={handleClose}
                    >
                      {constants.labels.submit}
                    </button>
                  </div>
                </div>
              </Menu>
              {workOrders.length > 0 && (
                <button
                  className="bg-primary px-4 py-[0.356rem] text-white rounded-lg font-semibold text-md cursor-pointer md:max-w-min w-full"
                  onClick={handleDownloadClick}
                  disabled={workOrders.length === 0}
                >
                  <span className="text-md">Download</span>
                </button>
              )}
            </div>
          </div>
          <TableContainer className="shadow-md">
            <Table>
              <TableHead>
                <TableRow>
                  {constants.workOrderColumns.map((column: any) => (
                    <TableCell key={column}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {workOrders.length > 0 ? (
                  workOrders?.map((workOrder: any) => (
                    <TableRow key={workOrder.id}>
                      <TableCell>
                        {getDateTime(workOrder.date) || "-"}
                      </TableCell>
                      <TableCell>{workOrder.orderNumber || "-"}</TableCell>
                      <TableCell>
                        {workOrder.mtUser?.customer?.name || "-"}
                      </TableCell>
                      <TableCell>{workOrder.mtUser?.name || "-"}</TableCell>
                      <TableCell>{workOrder.spUser?.name || "-"}</TableCell>
                      <TableCell>{workOrder?.apartmentName || "-"}</TableCell>
                      <TableCell>{workOrder.service?.name || "-"}</TableCell>
                      <TableCell>
                        {workOrder.orderRequest?.serviceType || "-"}
                      </TableCell>
                      <TableCell>
                        <StatusBadge
                          status={workOrder.status || "-"}
                        ></StatusBadge>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      {constants.messages.noWorkOrdersFound}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {workOrders?.length > 0 && (
          <div className="flex flex-col md:flex-row md:justify-between items-center gap-2 mt-2">
            <p className="font-poppins text-sm text-[#A098AE]">
              Showing{" "}
              <b>
                {recordsOnCurrentPage} -{" "}
                {totalRecords < constants.recordsPerPage
                  ? totalRecords
                  : constants.recordsPerPage}
              </b>{" "}
              from <b>{totalRecords}</b> data
            </p>
            <Stack spacing={2}>
              <Pagination
                page={page}
                count={totalPages}
                siblingCount={0}
                boundaryCount={1}
                onChange={handlePageChange}
                shape={"rounded"}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: ArrowLeftIcon,
                      next: ArrowRightIcon,
                    }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkOrders;
