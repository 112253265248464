// React import
import { useState } from 'react';

// Constants import
import constants from '../../App.constants';

// Service import
import { adminLogin } from '../../services/auth.service';

// Material import
import { Alert, Stack } from '@mui/material';

// Component import
import { LoadingModal } from '../../components/Modals';

// Login Container
const Login = () => {
  // Manages loginCredentials' state
  const [loginCredentials, setLoginCredentials] = useState({
    email: '',
    password: '',
  });

  // Manages Loader state
  const [isLoading, setIsLoading] = useState(false);

  // Manages Error message state
  const [loginFailedMessage, setLoginFailedMessage] = useState('');

  /**
   * Handles input changes
   * @param event
   */
  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setLoginCredentials((values) => ({ ...values, [name]: value }));
  };

  /**
   * Handles form submission
   * @param event
   */
  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    adminLogin(loginCredentials)
      .then((response: any) => {
        localStorage.setItem('token', response.data.data.accessToken);
        localStorage.setItem('email', response.data.data.email);
        localStorage.setItem('isAuthenticated', '1');
        setIsLoading(false);
        window.location.href = '/';
      })
      .catch((error: any) => {
        setIsLoading(false);
        if(error.response.data.errors) {
          setLoginFailedMessage(error.response.data.errors[0].message);
        } else {
          setLoginFailedMessage('Something went wrong!');
        }
      });
  };
  return (
    <div>
      <LoadingModal isLoading={isLoading} />
      <div className='flex flex-col md:flex-row min-h-screen w-full font-inter bg-[#F5F5F5] justify-center'>
        <div className='hidden md:flex flex-row w-1/2 bg-primary justify-center items-center'>
          <img
            className='h-[15.37rem] w-[20.4375rem]'
            src={constants.svgs.logoTurn}
            alt='Turn Tech Logo'
          />
        </div>
        <div className='flex md:hidden flex-row w-100 justify-center items-center'>
          <img
            className='h-[10rem] w-[15rem]'
            src={constants.svgs.iconTurnMobile}
            alt='Turn Tech Logo'
          />
        </div>
        <div className='bg-[#F5F5F5] w-100 md:w-1/2 z-10'>
          <div className='flex flex-col justify-center h-full md:px-[5rem] px-[2rem]'>
            <p className='text-[#333333] text-4xl mt-[2.125rem] font-bold text-center md:text-left'>
              {constants.messages.welcomeLoginForm}
            </p>
            <p className='font-bold text-xl text-primary text-left mt-[3rem]'>
              {constants.labels.signIn}
            </p>
            <p className='font-normal text-[#525252] text-sm'>
              {constants.messages.loginForm}
            </p>
            <form
              className='mt-[2.125rem] w-full'
              onSubmit={handleFormSubmit}
              onChange={() => {
                setLoginFailedMessage('');
              }}>
              <Stack spacing={2}>
                <input
                  className='w-full py-4 px-5 rounded-md outline-none'
                  type='email'
                  name='email'
                  onChange={handleChange}
                  value={loginCredentials.email}
                  required
                  placeholder='Email Address'
                />
                <input
                  className='w-full py-4 px-5 rounded-md outline-none'
                  type='password'
                  name='password'
                  onChange={handleChange}
                  value={loginCredentials.password}
                  required
                  placeholder='Password'
                />
                {loginFailedMessage && (
                  <Alert
                    severity='error'
                    onClick={() => setLoginFailedMessage('')}>
                    {loginFailedMessage}
                  </Alert>
                )}
                <button
                  className='w-full p-4 rounded-md bg-[#00AEEF] text-white'
                  type='submit'>
                  {constants.labels.signIn}
                </button>
              </Stack>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
