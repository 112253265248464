// Router import
import { Navigate, Route, Routes } from 'react-router-dom';

// Container import
import { AddCustomer, CustomersList } from '../containers/Customer';
import Dashboard from '../containers/Dashboard';
import WorkOrders from '../containers/WorkOrders';

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />} />
      <Route path='/customer/' element={<CustomersList />} />
      <Route path='/customer/add' element={<AddCustomer />} />
      <Route path='/work-order' element={<WorkOrders />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default ProtectedRoutes;
