// Material imports
import { createTheme } from "@mui/material/styles";

// Customising mui theme
const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: '0.8px solid #CFD8E1',
          color: '#0A293B',
        },
        head: {
          padding: '0.5rem 1rem',
          backgroundColor: '#FAFAFA',
          color: '#597191',
        }
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
        },
      },
    }
  },
  palette: {
    primary: {
      main: '#00AEEF',
    }
  },
});

export default theme;