// React import
import React from 'react';

// Constants import
import constants from '../../App.constants';

// RecordCard props interface
export interface RecordCardProps {
  recordType: string;
  count: number;
  lastUpdated: string;
}

/**
 * Record Card component
 * @param {RecordCardProps} props
 */
const RecordCard = ({ recordType, count, lastUpdated }: RecordCardProps) => {

  return (
    <div className='bg-white flex flex-col h-[7rem] rounded-lg p-4 min-w-[18rem] justify-between'>
      <p className='text-black font-bold text-transform: capitalize'>{recordType}</p>
      <div className='flex flex-col text-[#8E8E8E] text-sm'>
        <div className='flex flex-row justify-between w-full'>
          <p>{constants.labels.totalNoOf.replace('{record}', recordType)}</p>
          <p className='font-bold'>{count}</p>
        </div>
        <div className='flex flex-row justify-between w-full'>
          <p>{constants.labels.lastAdded.replace('{record}', recordType)}</p>
          <p className='font-bold'>{lastUpdated}</p>
        </div>
      </div>
    </div>
  );
};

export default RecordCard;
