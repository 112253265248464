// React import
import React, { useEffect, useState } from 'react';

// Constants import
import constants from '../../App.constants';

// Material imports
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

// Router import
import { useLocation, useNavigate } from 'react-router-dom';

// Service import
import { logout } from '../../services/auth.service';
import { breadcrumbNameMap } from '../NavigationBreadCrumbs';

const Navigation = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const NavigationItemsList = [
    {
      name: constants.labels.dashboard,
      icon: constants.svgs.iconDashBoard,
      iconActive: constants.svgs.iconDashBoardActive,
      path: '/',
    },
    {
      name: constants.labels.customers,
      icon: constants.svgs.iconCustomer,
      iconActive: constants.svgs.iconCustomerActive,
      path: '/customer',
    },
    {
      name: constants.labels.workOrder,
      icon: constants.svgs.iconWorkOrder,
      iconActive: constants.svgs.iconWorkOrderActive,
      path: '/work-order',
    },
  ];

  // Manages selectedScreen state
  const [selectedScreen, setSelectedScreen] = useState(
    breadcrumbNameMap[location.pathname]
  );

  /**
   * Toggles selected screen flag
   * @param {string} screen
   * @returns {boolean}
   */
  const isSelected = (screen: string) => screen === selectedScreen;

  useEffect(() => {
    if (location.pathname === '/customer/add') {
      setSelectedScreen(constants.labels.customers);
    } else {
      setSelectedScreen(breadcrumbNameMap[location.pathname]);
    }
  }, [selectedScreen, navigate, location]);

  return (
    <main className='bg-white md:min-h-[calc(100vh-4rem)] z-50'>
      <List className='z-10 hidden md:block h-full'>
        <div className='flex flex-col h-full justify-between'>
          <div className='mt-2'>
            {NavigationItemsList.map((navItem) => (
              <ListItem
                key={navItem.name}
                onClick={() => {
                  navigate(navItem.path);
                }}>
                <div className={'px-2 py-2 cursor-pointer rounded-md' + (isSelected(navItem.name) ? ' bg-primary' : '')}>
                  <img
                    className='w-6 h-6'
                    src={
                      isSelected(navItem.name)
                        ? navItem.iconActive
                        : navItem.icon
                    }
                    alt='Nav item icon'
                  />
                </div>
              </ListItem>
            ))}
          </div>
          <ListItem
            key='logout'
            onClick={() => logout()}>
            <div className='px-2 py-2 cursor-pointer rounded'>
              <img
                className='w-6 h-6'
                src={constants.svgs.iconLogout}
                alt='Nav item icon'
              />
            </div>
          </ListItem>
        </div>
      </List>
      <div className='flex flex-row w-full h-[5rem] bg-[#FAFAFC] justify-center items-center gap-4 shadow-mobileNav md:hidden fixed bottom-0'>
        {NavigationItemsList.map((navItem) => (
          <button
            // className='flex flex-col justify-center items-center'
            className={'px-2 py-2 cursor-pointer rounded-md' + (isSelected(navItem.name) ? ' bg-primary' : '')}
            key={navItem.name}>
            <img
              className='h-[2rem] w-[2rem]'
              alt='Nav Items'
              onClick={() => {
                navigate(navItem.path);
              }}
              src={
                isSelected(navItem.name)
                  ? navItem.iconActive
                  : navItem.icon
              }
            />
            {/* <p
              className={
                isSelected(navItem.name)
                  ? 'text-xs text-primary'
                  : 'text-xs text-[#818181]'
              }>
              {navItem.name}
            </p> */}
          </button>
        ))}
      </div>
    </main>
  );
};

export default Navigation;
