// React imports
import React, { useEffect, useState } from 'react';

// Material imports
import {
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  PaginationItem,
} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

// Router import
import { useNavigate } from 'react-router-dom';

// Service import
import { getCustomer } from '../../services/customer.service';

// Modal import
import { LoadingModal } from '../../components/Modals';
import constants from '../../App.constants';

// Util Import
import { formateDate } from '../../Utils/date.util';

// Customer Container
export const CustomersList = () => {
  let navigate = useNavigate();

  // Manages page state
  const [page, setPage] = useState(1);

  // Manages totalPages state
  const [totalPages, setTotalPages] = useState(1);

  // Manages totalRecords state
  const [totalRecords, setTotalRecords] = useState(0);

  // Manages recordsOnCurrentPage state
  const [recordsOnCurrentPage, setRecordsOnCurrentPage] = useState(0);

  // Manages customerList state
  const [customerList, setCustomerList] = useState([]);

  // Manages Loader state
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Function to handle page change
   * @param {Event} event
   * @param {number} value
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  useEffect(() => {
    getCustomer(page, constants.recordsPerPage)
      .then((response: any) => {
        if (response.data.data.items) {
          setCustomerList(response.data.data.items);
        }
        if (response.data.data.meta) {
          setRecordsOnCurrentPage(response.data.data.meta.itemCount);
          setTotalPages(response.data.data.meta.totalPages);
          setTotalRecords(response.data.data.meta.totalRecords);
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
      });
  }, [page, totalPages, totalRecords, recordsOnCurrentPage]);

  return (
    <div className='h-full bg-white rounded-lg p-6'>
      <LoadingModal isLoading={isLoading} />
      {customerList?.length === 0 ? (
        <div className='flex flex-row justify-center w-full h-full md:pt-[5rem]'>
          <div className=' flex flex-col justify-center items-center bg-[#f1f4fc78] w-4/5 md:w-2/4 h-[15rem] rounded-[2rem] shadow-light gap-3'>
            <p className='text-lg text-[#363B64] text-center px-2'>
              {constants.messages.noCustomersFound}
            </p>
            <button
              className='bg-primary px-7 py-3 text-white rounded-xl font-semibold text-xl cursor-pointer'
              onClick={() => navigate('add')}>
              {constants.labels.uploadXlsx}
            </button>
          </div>
        </div>
      ) : (
        <div className='flex flex-col justify-between h-full'>
          <div className='flex flex-col gap-6 h-[33rem] md:h-[38rem]'>
            <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-2'>
              <p className='font-bold text-xl text-black'>{constants.labels.allCustomers}</p>
              <button
                className='bg-primary px-5 py-2 text-white rounded-lg font-semibold text-md cursor-pointer'
                onClick={() => navigate('add')}>
                {constants.labels.uploadXlsx}
              </button>
            </div>
            <TableContainer className='shadow-md'>
              <Table>
                <TableHead>
                  <TableRow>
                    {constants.customersListColumns.map((column: any) => (
                      <TableCell>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerList?.map((customer: any) => (
                    <TableRow key={customer.id}>
                      <TableCell>{customer.name || '-'}</TableCell>
                      <TableCell>{customer.totalOrders || 0}</TableCell>
                      <TableCell>
                        {formateDate(customer.lastPlaced) || '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className='flex flex-col md:flex-row md:justify-between items-center gap-2 mt-2'>
            <p className='font-poppins text-sm text-[#A098AE]'>
              Showing <b>{recordsOnCurrentPage} - {totalRecords < constants.recordsPerPage ? totalRecords : constants.recordsPerPage}</b>{' '}
              from <b>{totalRecords}</b> data
            </p>
            <Stack spacing={2}>
              <Pagination
                page={page}
                count={totalPages}
                siblingCount={1}
                boundaryCount={1}
                onChange={handlePageChange}
                shape={"rounded"}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: ArrowLeftIcon,
                      next: ArrowRightIcon,
                    }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
        </div>
      )}
    </div>
  );
};
