// Constants impory
import constants from '../App.constants';

// Third party import
import axios from 'axios';

// Function to get total customers, vendors and orders count
const getDashboardStats = async () => {
  return axios({
    method: 'GET',
    url: constants.apiBaseUrl + constants.apis.dashboardStats,
  });
};

export {
  getDashboardStats
};
