// Constants import
import constants from '../App.constants';

// Third party import
import axios from 'axios';

/**
 * Function to authenticate admin with provided credentials
 * @param {email: string, password: string} loginCedentials 
 */
const adminLogin = async (loginCedentials: { email: string, password: string }) => {
  return axios({
    method: 'POST',
    url: constants.apiBaseUrl + constants.apis.adminLogin,
    data: loginCedentials,
  });
};

// Function to logout the user
const logout = () => {
  localStorage.clear();
  window.location.reload();
  window.location.href = '/';
};

export {
  adminLogin,
  logout,
}