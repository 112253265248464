export const formateDate = (date: any) => {
  if (date) {
    let dateTemp = new Date(date);
    return dateTemp.toLocaleDateString("en-US");
  }
  return null;
};

export const getDateTime = (date: any) => {
  let dateTemp = new Date(date);
  let dateStr = dateTemp.toLocaleDateString("en-US");
  let timeStr = dateTemp.toLocaleTimeString("en-US",{ hour: '2-digit', minute: '2-digit' });
  return dateStr + ' ' + timeStr;
};
