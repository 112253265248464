// Constants impory
import constants from '../App.constants';

// Third party import
import axios from 'axios';

/**
 * Function to get all Customers
 * @param {number} currentPage
 * @param {number} recordsPerPage
 */
const getCustomer = async (currentPage: number, recordsPerPage: number) => {
  return axios({
    method: 'GET',
    url: constants.apiBaseUrl + constants.apis.customerDetails,
    params: {
      page: currentPage,
      limit: recordsPerPage,
    }
  });
};

/**
 * Function to upload xlsx file to add customer
 * @param {File} xlsxFile
 */
const addCustomerWithXlsxFile = async (xlsxFile: File) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  let formData = new FormData();
  formData.append('file', xlsxFile);
  return axios.post(constants.apiBaseUrl + 'admins/upload-excel-sheet', formData, config);
}

export {
  getCustomer,
  addCustomerWithXlsxFile,
}