// Material imports
import { Backdrop } from '@mui/material';

// Constants imports
import constants from '../../App.constants';

// LoadingModal props interface
export interface LoadingModalProps {
  isLoading: boolean;
  isLoginScreen?: boolean;
}

// Loading Modal
export const LoadingModal = ({
  isLoading,
}: LoadingModalProps) => {
  return (
    <Backdrop
      className='z-40'
      open={isLoading}
      sx={{
        backgroundColor: 'rgba(0, 174, 239, 0.4)'
      }}>
      <img
        className='h-[20rem] w-[20rem]'
        alt='Loader'
        src={constants.gifs.loaderTurnWhite}
      />
    </Backdrop>
  );
};
