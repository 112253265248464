// Constants impory
import constants from '../App.constants';

// Third party import
import axios from 'axios';

/**
 * Function to fetch Work Orders
 * @param dateRange
 * @param currentPage
 */
const getWorkOrders = async (dateRange: any, currentPage: number, status?: string) => {
  let params = status ? {
    page: currentPage,
    limit: constants.recordsPerPage,
    status,
    from: dateRange.from,
    till: dateRange.till,
  } : {
    page: currentPage,
    limit: constants.recordsPerPage,
    from: dateRange.from,
    till: dateRange.till,
  }
  return axios({
    method: 'GET',
    url: constants.apiBaseUrl + constants.apis.workOrders,
    params
  });
};

/**
 * Function to fetch Work Orders
 * @param dateRange
 * @param status
 */
const downloadWorkOrders = async (dateRange: any, status?: string) => {
  let params = status ? {
    status,
    from: dateRange.from,
    till: dateRange.till,
  } : {
    from: dateRange.from,
    till: dateRange.till,
  }
  return axios({
    method: 'GET',
    url: constants.apiBaseUrl + constants.apis.workOrders + '/download',
    responseType: 'blob',
    params,
  });
};

export {
  getWorkOrders,
  downloadWorkOrders,
}