// Router import
import { Navigate, Route, Routes } from 'react-router-dom';

// Container import
import Login from '../containers/Login';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='*' element={<Navigate to='/login' />} />
    </Routes>
  );
};

export default PublicRoutes;
